<template>
	<v-sheet class="recurring-main-listing" style="height: calc(87vh - 88px)">
		<template>
			<div class="overflow-y" style="max-height: calc(100vh - 306px)">
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<th class="p-2 light-blue-bg custome-table">SR.No.</th>
							<th class="p-2 light-blue-bg custome-table">WebSite URL</th>
							<th class="p-2 light-blue-bg custome-table">Target Google</th>
						</tr>
					</thead>
					<!-- <pre>{{customerUrl[0].customer_urls}}</pre> -->
					<tbody v-if="customerUrl.length">
						<tr v-for="(row, index) in customerUrl" :key="index">
							<td class="p-2 border-top-light-grey">
								<ShowValue v-if="false" :object="row" object-key="id" label="sr"></ShowValue>
								{{ index + 1 }}
							</td>
							<td class="p-2 border-top-light-grey">
								<span v-if="getValue(row, 'website_urls')">
									{{ row.website_urls }}
								</span>
								<em class="text-muted" v-else>no urls</em>
							</td>
							<td class="p-2 border-top-light-grey">
								<span v-if="getValue(row, 'url_target_google.text')">
									{{ row.url_target_google.text }}
								</span>
								<em class="text-muted" v-else>no target google</em>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="7">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There <span class="text-lowercase">are no SEO Url at the moment.</span>
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</template>
	</v-sheet>
</template>

<style>
.custome-table {
	background-color: #eaedef;
	border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
	border-bottom: solid 1px #bcc0c9 !important;
}
</style>
<script>
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import objectPath from "object-path";
/* import Chip from "@/view/components/Chip"; */

export default {
	name: "Seo-report-listing",
	title: "Listing Invoice",
	/* mixins: [ListingMixin], */
	data() {
		return {
			pageTitle: "Seo-report",
			pageBreadcrumbs: [{ text: "Seo-report", disabled: true }],
			endpoint: "seo-report",
			defaultFilter: {},
			count: {},
			status: "all",
			pageLoading: false,
			customerUrl: [],
		};
	},
	props: {
		customerId: {
			type: Number,
			default: 0,
		},
		serviceId: {
			type: Number,
			default: 0,
		},
		/*  customerUrl: {
			type: Array,
		}, */
	},
	// watch: {
	// 	serviceId(param) {
	// 		this.getSeo(param);
	// 	},
	// },
	methods: {
		getValue(object, keyString) {
			return objectPath.get(object, keyString);
		},
		getSeo() {
			/* let status = this.status; */
			/* 	if (status == "checked-out" && this.status_filter) {
				status = this.status_filter;
			} */
			ApiService.get(`contract-seo-urls/${this.serviceId}`)
				.then(({ data }) => {
					if (data) {
						this.customerUrl = data;
					}
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		ShowValue,
		/*  Chip */
	},
	mounted() {
		this.getSeo();
		/* console.log(this.showing_string,'sjdfshgf.fhsjhdajkshdjas')
		this.$store.commit(SET_SHOWING_STRING, this.showing_string); */
	},
};
</script>
