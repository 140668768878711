<template>
	<!-- style="max-height: unset !important" -->
	<v-autocomplete
		class="tag-autocomplete"
		v-model="model"
		:filter="filter"
		:hide-no-data="!search"
		:items="items"
		:search-input.sync="search"
		hide-selected
		hide-details
		multiple
		outlined
		:disabled="disabled"
		:loading="loading"
		placeholder="Search Tags..."
		small-chips
		color="primary"
		item-color="primary"
		return-object
		:menu-props="{
			contentClass: 'tag-autocomplete-list',
		}"
	>
		<!-- solo
		flat
		dense
		filled -->
		<template v-slot:selection="{ attrs, item, parent, selected }">
			<v-list-item row>
				<v-chip
					v-if="item === Object(item)"
					v-bind="attrs"
					:style="{ 'background-color': item.color }"
					text-color="white"
					:input-value="selected"
					small
				>
					<span class="pr-2">
						{{ item.text }}
					</span>
					<v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
				</v-chip>
			</v-list-item>
		</template>
		<template v-slot:item="{ index, item }">
			<v-chip :style="{ 'background-color': item.color }" text-color="white" dark small>
				{{ item.text }}
			</v-chip>
		</template>
		<template v-slot:no-data>
			<!-- <div class=""> -->
			<div class="mx-2 my-2 d-flex tag-autocomplete-new-chip">
				<div style="width: 70px; border-right: solid 1px #777">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<v-icon style="height: 34px; font-size: 36px !important" :style="{ color: item_color }"
									>mdi-record</v-icon
								>
								<v-icon>mdi-chevron-down</v-icon>
							</div>
						</template>
						<div class="bg-white mt-2" style="height: 140px">
							<v-row class="px-2" style="width: 366px; margin: 0px !important">
								<v-col
									v-for="(color, index) in colors"
									:key="`mdi-record-${index}`"
									style="min-width: 50px; max-width: 50px; padding: 4px !important"
									class="text-center bg-hover-tag"
									v-on:click="select_color(color)"
								>
									<v-icon
										style="height: 34px; width: 34px; font-size: 42px !important"
										:style="{ color: color }"
										v-if="item_color == color"
										>mdi-check-circle</v-icon
									>
									<v-icon
										style="height: 34px; width: 34px; font-size: 48px !important"
										:style="{ color: color }"
										v-else
										>mdi-record</v-icon
									>
								</v-col>
							</v-row>
						</div>
					</v-menu>
				</div>
				<div class="px-4 cursor-pointer" v-on:click="create_tag" style="width: 80%">
					<p class="m-0">
						Create a new tag "<span style="font-weight: 700">{{ search }}</span
						>"
					</p>
				</div>
			</div>
			<!-- </div> -->
		</template>
	</v-autocomplete>
</template>

<script>
import {
	GET,
	/* POST */
} from "@/core/services/store/request.module";
import { cloneDeep } from "lodash";

export default {
	name: "tag-autocomplete-list",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data: () => ({
		item_color: "#e2b910",
		loading: false,
		disabled: false,
		activator: null,
		attach: null,
		colors: [
			"#0dd3d3",
			"#e2b910",
			"#61bfc1",
			"#f28840",
			"#d359aa",
			"#83b727",
			"#b58a61",
			"#ffac14",
			"#48b7b4",
			"#f78b84",
			"#57c065",
			"#018ecd",
			"#0995ba",
			"#33c5f2",
			"#ff65cb",
			"#b79d7d",
			"#3b92ff",
			"#ba83f2",
			"#d3854a",
			"#99a8bd",
		],
		editing: null,
		editingIndex: -1,
		items: [],
		nonce: 1,
		menu: false,
		model: [],
		x: 0,
		search: null,
		y: 0,
	}),

	watch: {
		value() {
			this.init_tags();
		},
		model(val, prev) {
			if (val.length === prev.length) return;
			this.emit_data();
		},
	},

	methods: {
		emit_data() {
			this.$nextTick(() => {
				this.$emit("input", this.model);
				this.$emit("change", this.model);
			});
		},
		create_tag() {
			this.pageLoading = true;
			this.items.push({
				color: this.item_color,
				text: this.search,
				total: 0,
			});
			this.model.push({
				color: this.item_color,
				text: this.search,
			});

			// this.$store
			// 	.dispatch(POST, { url: "lead-tag/add", data: this.model })
			// 	.then((data) => {
			// 		console.log({ data });
			// 	})
			// 	.catch((error) => {
			// 		console.log(error);
			// 	}).finally(() => {
			// 		this.pageLoading = false;
			// });

			this.search = null;
			this.init_color();
			this.emit_data();
		},
		init_color() {
			this.item_color = this.colors[Math.floor(Math.random() * this.colors.length)];
		},
		select_color(param) {
			this.item_color = param;
		},
		init_tags() {
			if (this.value && this.value.length > 0) {
				this.model = cloneDeep(this.value);
				this.nonce = this.value.length;
			}
		},
		get_tags() {
			this.loading = true;
			this.$store
				.dispatch(GET, { url: `lead-tag/list` })
				.then((data) => {
					this.items = data;
					this.$nextTick(() => {
						this.init_tags();
					});
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.loading = false;
				});
		},
		edit(index, item) {
			if (!this.editing) {
				this.editing = item;
				this.editingIndex = index;
			} else {
				this.editing = null;
				this.editingIndex = -1;
			}
		},
		filter(item, queryText, itemText) {
			if (item.header) return false;

			const hasValue = (val) => (val != null ? val : "");

			const text = hasValue(itemText);
			const query = hasValue(queryText);

			return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
		},
	},

	mounted() {
		this.get_tags();
		this.init_color();
	},
};
</script>
